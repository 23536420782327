import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import { Product } from '@/entities/Package';
import urls from '@/shared/const/urls';

export const getProductById = createAsyncThunk<
  Product,
  number,
  ThunkConfig<string>
>('getProductById', async (productId, thunkApi) => {
  const { extra, dispatch, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<Product>(
      urls.products.getProductById(productId),
    );

    if (!response.data) throw new Error();

    return response.data;
  } catch (e) {
    console.log(e);
    return rejectWithValue('error');
  }
});
