const countryList = [
  'AU',
  'AT',
  'BE',
  'BR',
  'BG',
  'CA',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'HK',
  'HU',
  'IN',
  'ID',
  'IE',
  'IT',
  'JP',
  'LV',
  'LI',
  'LT',
  'LU',
  'MY',
  'MT',
  'MX',
  'NL',
  'NZ',
  'NO',
  'PL',
  'PT',
  'RO',
  'SG',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TH',
  'TT',
  'AE',
  'GB',
  'US',
  'UY',
];

export const selectPaymentCountry = (countryCode?: string) => {
  const isCountryEqual = (element: string) => element === countryCode;
  if (countryList.some(isCountryEqual)) return countryCode;

  return 'DE';
};
