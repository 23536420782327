import { useTranslations } from 'next-intl';
import { memo } from 'react';
import cls from './changePackageBlock.module.css';
import { InfoCircleOutlinedSVG } from '@/shared/assets/svg/Statuses';
import { currentProductInfoCls } from '@/shared/lib/helpers/getProductInfoCls';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { Modal } from '@/shared/ui/Modal';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export interface ChangePackageBlockProps {
  packageName?: string;
  packageType?: string;
}

export const ChangePackageBlock = memo((props: ChangePackageBlockProps) => {
  const { packageName, packageType } = props;

  const telegramLink = process.env.NEXT_PUBLIC_BOT_URL;

  const t = useTranslations();

  const redirectToTelegram = () => window.open(telegramLink);

  return (
    <Modal isOpen>
      <VStack className={cls.wrapper} gap='x-l'>
        <Flex
          direction='row'
          gap='s'
          justify='start'
          align='center'
          wrap='wrap'
        >
          <Icon
            color='#fff'
            height={24}
            width={24}
            Svg={InfoCircleOutlinedSVG}
          />
          <Text
            as='h3'
            variant='h3'
            className={cls[currentProductInfoCls(1, packageName)]}
          >
            {packageName === 'Screener' ? packageName : packageType}
          </Text>
          <Text
            as='h3'
            variant='h3'
            className={cls[currentProductInfoCls(2, packageName)]}
          >
            {packageName === 'Screener' ? packageType : packageName}
          </Text>
          <Text as='h3' variant='h3' className={cls['ordinary-text']}>
            {t('is active')}
          </Text>
        </Flex>
        <Text as='p' variant='body-l-light'>
          {t('Please wait')}
        </Text>
        <Text as='p' variant='body-s'>
          {t('You can also')}
        </Text>
        <Button
          fullWidth
          type='submit'
          text={t('Choose another subscription')}
          onClick={redirectToTelegram}
        />
      </VStack>
    </Modal>
  );
});
