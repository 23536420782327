import { FC, memo, useCallback } from 'react';
import { CheckUserEmailBlock } from './CheckUserEmailBlock/CheckUserEmailBlock';
import ErrorResult from './ErrorResult/ErrorResult';
import SuccessResult from './SuccessResult/SuccessResult';
import cls from './afterpayStatus.module.css';
import {
  TPropsWithTryAgain,
  TPropsWithoutTryAgain,
} from '../model/types/types';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { PrevActivatedPackageModal } from '@/widgets/PrevActivatedPackageModal';

interface CommonAfterpayStatusProps {
  className?: string;
  status?: string;
  userEmail?: string;
  error?: string;
}

type AfterpayStatusPropsWithTryAgain = CommonAfterpayStatusProps &
  TPropsWithTryAgain;

type AfterpayStatusPropsWithoutTryAgain = CommonAfterpayStatusProps &
  TPropsWithoutTryAgain;

type AfterpayStatusProps =
  | AfterpayStatusPropsWithoutTryAgain
  | AfterpayStatusPropsWithTryAgain;

export const AfterpayStatus: FC<AfterpayStatusProps> = memo(
  ({
    className,
    status,
    userEmail,
    showTryAgainButton,
    handleTryAgain,
    error,
  }) => {
    const { isMobile, isTablet } = useDetectDevice();

    const returnComponent = useCallback(() => {
      if (status === 'paymentSucceeded' || status === 'paid')
        return (
          <SuccessResult
            userEmail={userEmail}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        );
      if (status === 'paymentError' || status === 'failed' || status === 'open')
        return (
          <ErrorResult
            showTryAgainButton={showTryAgainButton}
            handleTryAgain={handleTryAgain}
            error={error}
          />
        );
      if (status === 'checkEmailAfterPayment') return <CheckUserEmailBlock />;
      if (status === 'packagePurchasedBefore')
        return <PrevActivatedPackageModal isOpen />;
    }, [
      userEmail,
      error,
      showTryAgainButton,
      handleTryAgain,
      isMobile,
      isTablet,
      status,
    ]);

    return (
      <div
        className={classNames(cls['after-pay'], {
          [cls['is-status-succeeded']]: status === 'paymentSucceeded',
        })}
      >
        {returnComponent()}
      </div>
    );
  },
);
