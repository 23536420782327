import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './checkUserEmailBlock.module.css';
import { getRouteWelcome } from '@/shared/const/router';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { Button } from '@/shared/ui/Buttons';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { setPromoCode } from '@/widgets/FormPayment';

export const CheckUserEmailBlock: FC = memo(() => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { push } = useAppNavigation();

  const redirectToMain = () => {
    dispatch(setPromoCode({ oldPrice: '', price: '' }));
    push({ paramsToDelete: ['productId'], path: getRouteWelcome() });
  };

  return (
    <VStack gap='2-x-l' className={cls['main-content']}>
      <VStack gap='m' className={cls['text-block']} justify='center'>
        <Text
          align='center'
          as='h2'
          variant='h2-light'
          className={cls['title-text']}
        >
          {t('Payment status')}
        </Text>
        <Text as='p' variant='body-l-light'>
          {t('Payment description', { userEmail: 'test@mail.com' })}
        </Text>
      </VStack>
      <Button
        fullWidth
        type='submit'
        text={t('Go back to the main page')}
        onClick={redirectToMain}
      />
    </VStack>
  );
});
