import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './errorResult.module.css';
import { ErrorCircleSVG } from '@/shared/assets/svg/Statuses';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export interface IErrorResultProps {
  className?: string;
  showTryAgainButton: boolean;
  handleTryAgain?: () => void | undefined;
  error?: string;
}

const ErrorResult: FC<IErrorResultProps> = memo(props => {
  const { className, showTryAgainButton, handleTryAgain, error } = props;
  const t = useTranslations();

  const errorMessage = (errorCode: string | undefined) => {
    switch (errorCode) {
      case 'insufficient_funds':
        return t('There are insufficient funds on your card');
      case 'card_declined':
        return t('Failed to verify PIN');
      case 'expired_card':
        return t('The specified card has expired');
      default:
        return t('Unexpected error');
    }
  };

  return (
    <VStack gap='2-x-l' justify='between' className={cls.wrapper}>
      <VStack gap='x-l' className={cls['info-block']} align='center'>
        <Icon color='#FF5449' height={120} width={120} Svg={ErrorCircleSVG} />
        <VStack gap='m' justify='center' align='center'>
          <Text
            as='h2'
            variant='h2-light'
            align='center'
            className={cls['ordinary-text']}
          >
            {t('Payment failed')}
          </Text>
          <Text
            as='p'
            variant='body-l-light'
            className={cls['ordinary-text']}
            align='center'
          >
            {t(errorMessage(error))}
          </Text>
        </VStack>
      </VStack>
      {!!showTryAgainButton && (
        <Button
          fullWidth
          className={cls.btn}
          type='submit'
          text={t('Try again')}
          imgLocation='right'
          onClick={handleTryAgain}
        />
      )}
    </VStack>
  );
});

export default ErrorResult;
