const markets = ['Forex', 'Crypto', 'Stocks', 'Screener'];
// Important do not change the order of types
const types = [
  'Trial Fundamental',
  'Trial',
  'Monthly',
  'Fundamental',
  'Black Friday',
];

export const getProductTitleValues = (productFullTitle?: string) => {
  let productUIName;
  let productUIType;

  if (!productFullTitle) {
    productUIName = 'Unexpected name';
    productUIType = 'Unexpected type';
  }

  if (productFullTitle) {
    for (let i = 0; i < markets.length; i++)
      if (productFullTitle?.includes(markets[i])) {
        productUIName = markets[i];
        break;
      }

    for (let i = 0; i < types.length; i++)
      if (productFullTitle?.includes(types[i])) {
        productUIType = types[i];
        break;
      }
  }

  return { productUIName, productUIType };
};
