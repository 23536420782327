import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './menuLinks.module.css';
import { getRouteWelcome } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useAppPathname } from '@/shared/lib/router/navigation';
import { Flex } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IMenuLinks {
  className?: string;
  onClick: () => void;
}

export const MenuLinks: FC<IMenuLinks> = memo(props => {
  const { className, onClick } = props;
  const pathname = useAppPathname();
  const t = useTranslations();
  const { isMobile, isTablet } = useDetectDevice();
  const visibleMobileMenu = isMobile || isTablet;

  const handleClickScroll = (value: string) => {
    const block = document.getElementById(value);
    block?.scrollIntoView({ behavior: 'smooth' });
    visibleMobileMenu && onClick();
  };

  const linksArray = useMemo(
    () =>
      [
        { id: 'startBlock', title: t('About me') },
        { id: 'advantagesBlock', title: t('Advantages') },
        { id: 'screenerBlock', title: 'Screener' },
        { id: 'pricingBlock', title: t('Pricing') },
        { id: 'reviewsBlock', title: t('Reviews') },
      ] as { id: string; title: string }[],
    [t],
  );

  const links = useMemo(
    () => (pathname === getRouteWelcome() ? linksArray : []),
    [linksArray, pathname],
  );

  return (
    <Flex
      align='start'
      direction={visibleMobileMenu ? 'column' : 'row'}
      className={classNames(cls.links, {}, [className])}
      gap='x-l'
    >
      {!!links &&
        !!links.length &&
        links.map(item => (
          <Text
            key={item.id}
            id={`header-${item.id}`}
            wrap='nowrap'
            variant='button-default'
            onClick={() => handleClickScroll(item.id)}
          >
            {item.title}
          </Text>
        ))}
    </Flex>
  );
});
