'use client';

import { useTranslations } from 'next-intl';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import cls from './header.module.css';
import { getProfileUser } from '@/entities/User';
import { LogoIconLink } from '@/features/LogoIconLink';
import { MenuLinks } from '@/features/MenuLinks';
import { SelectLanguage } from '@/features/SelectLanguage';
import { getPaymentStatus } from '@/features/StripePaymentElement';
import {
  CloseOutlinedSVG,
  MenuOutlinedSVG,
} from '@/shared/assets/svg/Additional';

import { getRouteSignIn } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useQueryParams } from '@/shared/lib/hooks/useQueryParams';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { HStack } from '@/shared/ui/Stack';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { MobileMenu } from '@/widgets/MobileMenu';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { SidebarItemType } from '@/widgets/Sidebar';

interface IHeaderProps {
  className?: string;
}

export const Header: FC<IHeaderProps> = memo(props => {
  const { className } = props;

  const t = useTranslations();
  const { push } = useAppNavigation();
  const searchParams = useQueryParams();

  const paymentStatus = useAppSelector(getPaymentStatus);
  const userProfile = useAppSelector(getProfileUser);

  const [viewMobileMenu, setViewMobileMenu] = useState(false);

  const burgerButtonIcon = useMemo(
    () => (viewMobileMenu ? CloseOutlinedSVG : MenuOutlinedSVG),
    [viewMobileMenu],
  );

  const { isMobile, isTablet, isTabletHorizontal, isDesktop } =
    useDetectDevice();

  const visibleSelect = isTabletHorizontal || isDesktop;
  const visibleMobileMenu = isMobile || isTablet;

  const onToggleMobileMenu = () => setViewMobileMenu(!viewMobileMenu);
  const redirectToSignIn = useCallback(
    () => push({ paramsToDelete: ['partner'], path: getRouteSignIn() }),
    [push],
  );

  const handleClickScroll = (value: string) => {
    const block = document.getElementById(value);
    block?.scrollIntoView({ behavior: 'smooth' });
    visibleMobileMenu && onToggleMobileMenu();
  };

  const showSelectLang = useMemo(
    () =>
      visibleSelect && typeof paymentStatus !== 'string' ? (
        <SelectLanguage />
      ) : null,
    [visibleSelect, paymentStatus],
  );

  const linksArray = useMemo(
    () =>
      [
        { id: 'startBlock', text: t('About me') },
        { id: 'advantagesBlock', text: t('Advantages') },
        { id: 'screenerBlock', text: 'Screener' },
        { id: 'pricingBlock', text: t('Pricing') },
        { id: 'reviewsBlock', text: t('Reviews') },
      ] as SidebarItemType[],
    [t],
  );

  const showLoginButton = useMemo(
    () =>
      userProfile?.name && userProfile?.name.length > 0 ? null : (
        <Button
          className={cls['entrance-button']}
          typeButton='accent-outline'
          text={t('Log in')}
          onClick={redirectToSignIn}
        />
      ),
    [userProfile, redirectToSignIn, t],
  );

  return (
    <HStack
      max
      className={classNames(cls['header-container'], {}, [className])}
    >
      <HStack max align='center' justify='between' className={cls.header}>
        <LogoIconLink />
        <HStack>
          {!!visibleSelect && <MenuLinks onClick={onToggleMobileMenu} />}
          {showSelectLang}
          {!searchParams.redirectFrom && showLoginButton}
          {!!visibleMobileMenu && (
            <Icon
              clickable
              color='#fff'
              width={32}
              height={32}
              Svg={burgerButtonIcon}
              className={cls['mobile-toggle-menu']}
              onClick={onToggleMobileMenu}
            />
          )}
          {!!viewMobileMenu && (
            <MobileMenu
              type='text'
              links={linksArray}
              onClickItem={handleClickScroll}
            />
          )}
        </HStack>
      </HStack>
    </HStack>
  );
});
