import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './loadErrorBlock.module.css';
import { ErrorCircleSVG } from '@/shared/assets/svg/Statuses';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export const LoadErrorBlock: FC<{ getClientSecret: () => Promise<void> }> =
  memo(({ getClientSecret }) => {
    const t = useTranslations();
    const { isDesktop } = useDetectDevice();

    return (
      <VStack
        className={cls.wrapper}
        align={isDesktop ? 'start' : 'center'}
        gap='2-x-l'
      >
        <VStack className={cls['info-block']} gap='2-x-l' align='center'>
          <Icon width={120} height={120} Svg={ErrorCircleSVG} />
          <VStack className={cls['text-block']} gap='m'>
            <Text
              align='center'
              as='h2'
              variant='h2-light'
              className={cls['title-text']}
            >
              {t('Something went wrong')}
            </Text>
            <Text as='p' variant='body-l-light'>
              {t('error payment')}
            </Text>
          </VStack>
        </VStack>
        <Button
          fullWidth
          className={cls.btn}
          type='submit'
          text={t('Try again')}
          onClick={getClientSecret}
        />
      </VStack>
    );
  });
