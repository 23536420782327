import { useTranslations } from 'next-intl';
import { FC, memo, useCallback, useEffect } from 'react';
import cls from './successResult.module.css';
import {
  getDataUser,
  getIsNewUser,
  getNewUser,
  getProfileUser,
} from '@/entities/User';
import SuccessCircle from '@/shared/assets/svg/Statuses/SuccessCircle.svg';
import { getRoutePersonAccMain, getRouteSignIn } from '@/shared/const/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { Flex, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

export interface SuccessResultProps {
  className?: string;
  userEmail?: string;
  isMobile: boolean;
  isTablet: boolean;
}

const SuccessResult: FC<SuccessResultProps> = memo(
  ({ userEmail, isMobile }) => {
    const { push } = useAppNavigation();
    const t = useTranslations();
    const dispatch = useAppDispatch();

    const dataUser = useAppSelector(getDataUser);
    const isNewUser = useAppSelector(getIsNewUser);
    const userProfile = useAppSelector(getProfileUser);

    // const idPayment = useAppSelector(getIdPayment);
    // const telegramLink = process.env.NEXT_PUBLIC_BOT_URL;
    // const redirectToTelegram = () => {
    //   dispatch(setPromoCode({ oldPrice: '', price: '' }));
    //   window.open(`${telegramLink}?start=${idPayment}`);
    // };

    const redirectToMain = () =>
      push({ paramsToDelete: ['partner'], path: getRoutePersonAccMain() });

    const redirectToSignIn = useCallback(() => {
      if (isNewUser.passwordExpired)
        push({
          newParams: { fromSignUp: 'true' },
          paramsToDelete: ['partner'],
          path: getRouteSignIn(),
        });
      else
        push({
          paramsToDelete: ['fromSignUp', 'partner'],
          path: getRouteSignIn(),
        });
    }, [push, isNewUser]);

    useEffect(() => {
      dataUser?.id && dispatch(getNewUser(dataUser?.id));
    }, [dispatch, dataUser]);

    return (
      <VStack gap='2-x-l' justify='between' className={cls.wrapper}>
        <VStack gap='2-x-l' className={cls['info-block']} align='center'>
          <Icon color='#4cfec9' height={120} width={120} Svg={SuccessCircle} />
          <Text
            as='h2'
            variant='h2-light'
            align='center'
            className={cls['ordinary-text']}
          >
            {t('Payment successful!')}
          </Text>
          <VStack
            justify='center'
            align='center'
            gap='0'
            className={cls['text-block']}
          >
            <Flex
              justify='center'
              align='center'
              wrap='wrap'
              gap={isMobile ? '0' : 'x-s'}
              direction={isMobile ? 'column' : 'row'}
            >
              <Text
                as='p'
                variant='body-l-light'
                className={cls['ordinary-text']}
              >
                {t('We have  sent an email to the')}
                <span className={cls['green-text']}>{userEmail}</span>
                {t('with payment confirmation')}
              </Text>
            </Flex>
          </VStack>
        </VStack>
        <Button
          fullWidth
          text={t('Go to Personal Account')}
          imgLocation='right'
          onClick={
            userProfile?.name && userProfile?.name.length > 0
              ? redirectToMain
              : redirectToSignIn
          }
        />
      </VStack>
    );
  },
);

export default SuccessResult;
