import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayerData } from '../types/stripePaymentSchema';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const getUserById = createAsyncThunk<
  PayerData,
  number,
  ThunkConfig<string>
>('getUserById', async (userId, thunkApi) => {
  const { extra, dispatch, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<PayerData>(
      urls.user.getUserById(userId),
    );

    if (!response.data) throw new Error();

    return response.data;
  } catch (e) {
    console.log(e);
    return rejectWithValue('error');
  }
});
