import { useTranslations } from 'next-intl';
import { memo } from 'react';
import cls from './cardFormSkeleton.module.css';
import { calcSkeletonSizes } from '../../model/lib/helpers/calcSkeletonSizes';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { Skeleton } from '@/shared/ui/Skeleton/Skeleton';
import { HStack, VStack } from '@/shared/ui/Stack';

export interface CardFormSkeletonProps {
  productPrice?: string;
}

export const CardFormSkeleton = memo((props: CardFormSkeletonProps) => {
  const { productPrice } = props;

  const { isMobile, isTablet } = useDetectDevice();

  const { firstRow, secondRow, thirdRow, fourthRow } = calcSkeletonSizes(
    isMobile,
    isTablet,
  );

  const t = useTranslations();

  return (
    <VStack className={cls['skeleton-block-wrapper']} align='center'>
      <VStack className={cls['skeleton-block']} gap='3-x-l'>
        <HStack gap='2-x-l' className={cls['skeleton-row']}>
          <Skeleton
            width='50%'
            height={firstRow.height}
            className={cls.skeleton}
          />
          <Skeleton
            width='50%'
            height={firstRow.height}
            className={cls.skeleton}
          />
        </HStack>
        <VStack gap='m' className={cls['skeleton-row']}>
          <Skeleton
            width='24%'
            height={secondRow.firstBlock.height}
            className={cls.skeleton}
          />
          <Skeleton
            width='100%'
            height={secondRow.secondBlock.height}
            className={cls.skeleton}
          />
        </VStack>
        <HStack gap='2-x-l' className={cls['skeleton-row']}>
          <VStack gap='m' className={cls['skeleton-column']}>
            <Skeleton
              width='50%'
              height={thirdRow.firstBlock.height}
              className={cls.skeleton}
            />
            <Skeleton
              width='100%'
              height={thirdRow.secondBlock.height}
              className={cls.skeleton}
            />
          </VStack>
          <VStack gap='m' className={cls['skeleton-column']}>
            <Skeleton
              width='50%'
              height={thirdRow.firstBlock.height}
              className={cls.skeleton}
            />
            <Skeleton
              width='100%'
              height={thirdRow.secondBlock.height}
              className={cls.skeleton}
            />
          </VStack>
        </HStack>
        <VStack gap='m' className={cls['skeleton-row']}>
          <Skeleton
            width='25%'
            height={fourthRow.firstBlock.height}
            className={cls.skeleton}
          />
          <Skeleton
            width='100%'
            height={fourthRow.secondBlock.height}
            className={cls.skeleton}
          />
        </VStack>
      </VStack>
      <div className={cls.btn}>
        <Skeleton width='100%' height='48' className={cls.skeleton} />
      </div>
    </VStack>
  );
});
