import { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import cls from './Modal.module.css';
import { Icon } from '../../Icon';
import { Overlay } from '../../Overlay';
import { Portal } from '../../Portal';
import { CloseSVG } from '@/shared/assets/svg/Main/Management';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { useModal } from '@/shared/lib/hooks/useModal/useModal';
import { useTheme } from '@/shared/lib/hooks/useTheme/useTheme';
import { Justify } from '@/shared/types/ui';

interface IModalProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  lazy?: boolean;
  blur?: boolean;
  justify?: Justify;
  isOverlay?: boolean;
  withCloseButton?: boolean;
  colorCloseButton?: string;
}

export const Modal: FC<IModalProps> = ({
  className,
  children,
  isOpen,
  onClose,
  lazy = true,
  blur = true,
  justify = 'center',
  isOverlay = true,
  withCloseButton = false,
  colorCloseButton = '#fff',
  ...otherProps
}) => {
  const { theme } = useTheme();
  const { isMobile } = useDetectDevice();
  const { isClosing, isMounting, close } = useModal({
    animationDelay: 0,
    isOpen,
    onClose,
  });

  const size = useMemo(() => (isMobile ? 20 : 24), [isMobile]);

  if (lazy && !isMounting) return null;

  const mainClasses = classNames(cls.modal, { [cls.opened]: isOpen }, [
    theme,
    cls[justify],
  ]);

  const noOverlay = isOverlay ? '' : cls['no-overlay'];

  const contentClasses = classNames(cls.content, {}, [className, noOverlay]);

  return (
    <Portal>
      <div className={mainClasses}>
        <Overlay blur={blur} className={noOverlay} onClick={close} />
        <div {...otherProps} className={contentClasses}>
          {!!withCloseButton && !!onClose && (
            <Icon
              clickable
              Svg={CloseSVG}
              width={size}
              height={size}
              color={colorCloseButton}
              className={cls['close-button']}
              onClick={onClose}
            />
          )}
          {children}
        </div>
      </div>
    </Portal>
  );
};
