import { useTranslations } from 'next-intl';
import { FC, memo, useMemo } from 'react';
import cls from './prevActivatedPackageModal.module.css';
import { getSelectedProduct } from '@/entities/Package';
import { Information2SVG } from '@/shared/assets/svg/Main/Management';
import { getRouteWelcome } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { getProductTitleValues } from '@/shared/lib/helpers/getProductTitleValues';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { Modal } from '@/shared/ui/Modal';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { getErrorPackFormPayment } from '@/widgets/FormPayment';

interface IPrevActivatedPackageModalProps {
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
}

export const PrevActivatedPackageModal: FC<IPrevActivatedPackageModalProps> =
  memo(props => {
    const { className, isOpen, onClose } = props;
    const t = useTranslations();
    const { push } = useAppNavigation();

    const selectedProduct = useAppSelector(getSelectedProduct);
    const errorPack = useAppSelector(getErrorPackFormPayment);

    const currentTitleError = useMemo(
      () =>
        `${t(
          errorPack === 'errors.user.trial.product.notAllowed'
            ? 'Unfortunately, you cannot re-purchase a promotional subscription'
            : 'This plan is not available',
        )}.`,
      [errorPack, t],
    );
    const currentDescriptionError = useMemo(
      () =>
        `${t(
          errorPack === 'errors.user.trial.product.notAllowed'
            ? 'Choose another package and get back to us'
            : 'Try choosing another available option',
        )}.`,
      [errorPack, t],
    );

    const { productUIName, productUIType } = getProductTitleValues(
      selectedProduct?.title,
    );

    const redirectBack = () => {
      onClose?.();
      push({ path: getRouteWelcome() });
    };

    return (
      <Modal
        withCloseButton
        isOpen={isOpen}
        className={classNames(cls['prev-activated-package-modal'], {}, [
          className,
        ])}
        onClose={onClose}
      >
        <VStack gap='2-x-l'>
          <VStack>
            <Text as='h2' variant='h2'>
              {productUIType}
            </Text>
            <Text as='h3' variant='h3'>
              {productUIName}
            </Text>
          </VStack>
          <div className={cls.line} />
          <VStack gap='x-l'>
            <HStack gap='m' align='start'>
              <Icon Svg={Information2SVG} width={24} height={24} />
              <Text as='h4' variant='subheading'>
                {currentTitleError}
              </Text>
            </HStack>
            <Text variant='body-l' className={cls.description}>
              {currentDescriptionError}
            </Text>
          </VStack>
        </VStack>
        <Button
          fullWidth={false}
          className={cls.button}
          text={t('Choose another subscription')}
          onClick={redirectBack}
        />
      </Modal>
    );
  });
